<template>
    <div id="fail">
        <div class="box animated fadeInToAlpha">
            <div class="winbg"></div>
            <div class="titlebg"></div>
            <div class="winicon animated08 fadeInScale delay02"></div>
            <div class="wintitle"></div>
            <div class="close" @click="closeHandle"></div>
            <!-- <div class="wintext"></div> -->
            <div class="failtext animated fadeInToAlpha delay05">
                {{$t('game.fail.text')}}
            </div>
            <div class="text animated fadeInToAlpha delay05">{{$t('game.faildec')}}</div>
            <div class="backbtn animated08 fadeInScale delay05" @click="closeHandle">
                <cbtn colorClass="light" :txt="$t('game.fail.ok')" />

            </div>
            
            
        </div>
    </div>
</template>

<script>
    import Cbtn from '../cbtn/cbtn.vue'
    export default{
        name:'fail',
        data:function(){
            return{

            }
        },
        components: {
            Cbtn
        },
        methods:{
            closeHandle(){
                this.$emit('backEvent',{name:'game'})
            }
        }
    }
</script>


<style lang="scss">
    @import './fail.scss'
</style>