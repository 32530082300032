<template>
    <div id="notes">
        <div class="box animated fadeInToAlpha">
            <div class="winbg"></div>
            <div class="titlebg"></div>
            <div class="notestitle">{{$t('game.records')}}</div>
            <div class="close" @click="closeHandle"></div>
            <div class="listscroll">
                <div class="list">
                    <div class="item" v-for="(item,index) in list" :key="'r'+index">
                        <div class="blocknumber">{{item.block_number}}</div>
                        <div class="result" :class="{'fail':!item.win}">{{item.win?$t('game.recordList.goal'):$t('game.recordList.fail')}}</div>
                        <div class="scorebox">
                            <div class="score" :class="{'over':!item.win}"><span>{{(item.win?item.reward:item.value)/1000000}}</span> USD</div>
                            <!-- <div class="timer">{{timeTranslate(item.created_at)}}</div> -->
                            <div class="timer">{{item.human_time}}</div>
                        </div>
                        <div class="view" @click="goviewblock(item.block_number)">
                            <img src="@/assets/img/tronscan.png" alt="view on tronscan">
                            view 
                        </div>
                        <!-- <div class="txid">{{item.transaction_id}}</div> -->
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { RECORDS } from '../../../utils/api';
// import dayjs from 'dayjs';
export default {
    name: 'notes',
    data: function () {
        return {
            list: [],
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            RECORDS({address: window.walletAddress,locale:this.$i18n.locale}).then((res) => {
                this.list = res.data.data;
                console.log(this.list);
            })
        },
        closeHandle() {
            this.$emit('backEvent')
        },
        goviewblock(blocknumber){
            window.open("https://tronscan.org/#/block/"+blocknumber,"_blank");
        },
        // timeTranslate(val) {
        //     var utc = require('dayjs/plugin/utc')
        //     dayjs.extend(utc)
        //     return dayjs(val).utc().format('YYYY/MM/DD')
        // },
    }
}
</script>


<style lang="scss">
@import './notes.scss'
</style>