import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		queueing: false,
	},
	mutations: {
		
		setQueueing(state, queueing){
			state.queueing = queueing
		}
	
	},
	actions: {

	},
	modules: {

	}
});
