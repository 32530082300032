<template>
    <div id="bet">
        <div class="box animated08 fadeInToAlpha">
            <div class="winbg"></div>
            <div class="titlebg"></div>
            <div class="notestitle">{{$t('game.goalBetting')}}</div>
            <div class="close" @click="closeHandle"></div>
            <div class="listbg"></div>
            <div class="list animated08 fadeInToY">
                <div class="item " v-for="(item,index) in list" @click="itemHandle" :key="'bet-item-'+index" :data-index="index" :class="{'over':curid==index}">
                        <p><span>{{item}}</span>  USD</p>
                </div>
            </div>
            <div class="btn animated fadeInScale delay02">
                <cbtn v-if="!submited" :txt="$t('game.bet.done')" :colorClass="curid>=0?'light':'gray'" @backEvent="bet(list[curid])" />
                <!-- <a-spin v-else>
                    <a-icon slot="indicator" type="loading" style="font-size: 50px" spin />
                </a-spin> -->
                
                <div class="flex" v-else>
                    <div class="flex-icon">
                    <svg v-for="i in 6"  :key="'sp'+i" t="1664714192715" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4683" width="64" height="64"><path d="M539.84 601.6A676.8 676.8 0 0 0 585.6 544a271.68 271.68 0 0 0 246.4 68.48 64 64 0 0 0-26.24-125.44c-88.64 18.56-131.2-36.8-165.12-67.52a349.12 349.12 0 0 0-293.12-86.4 502.08 502.08 0 0 0-160 56.32 64 64 0 0 0 66.56 109.76 371.84 371.84 0 0 1 121.28-40.64c-2.56 9.28-10.56 35.84-21.76 72.32-60.8 178.56-50.88 177.92-244.48 177.92a64 64 0 0 0 0 128c64 0 241.92 17.6 352-133.12 71.04 71.36 64 84.48 64 224a64 64 0 0 0 128 0c-0.32-168.32 2.56-209.6-113.28-326.08z m79.36-281.6a144 144 0 1 0-144-144 144 144 0 0 0 144 144z m247.68 448a112 112 0 1 0 112 112 112 112 0 0 0-112-112z" :fill="curWId==i-1?'#f4c707':'#dbdbdb'" p-id="4684"></path></svg>
                    </div>
                    <div class="waittxt">
                        <span>{{$t('game.bet.queue')}}</span>
                        <span v-for="i in curWId+1" :key="'dot'+i">.</span>
                    </div>
                </div>
                
            </div>
            
        </div>
    </div>
</template>

<script>
import { KICK } from '../../../utils/api'
import Cbtn from '../cbtn/cbtn.vue'
    export default{
        name:'bet',
        data:function(){
            return{
                list:[1,10,20,50,100,200],
                curid:-1,
                isBtn:false,
                ismask:false,
                userUsdtBalance: 0,
                approvedAmount: 0,
                submited: false,
                waitItvl: {},
                curWId: 0,
            }
        },
        components: {
            Cbtn
        },
        mounted(){
            let _this = this;
            console.log(_this.$store.state.queueing);
            let _obj = setInterval(()=>{
                if (window.walletAddress && window.footballContract && window.usdtToken) {
                    clearInterval(_obj)
                    _this.getApprovedAmount();
                    window.usdtToken.balanceOf(window.walletAddress).call().then((res)=>{
                        console.log('usdt'+res.toNumber()/1000000);
                        _this.userUsdtBalance = res.toNumber() / 1000000;
                    })
                    
                }
                
            }, 50);
        },
        methods:{
            startWait(){
                let _this = this;
                this.waitItvl = setInterval(()=>{
                    if(!_this.$store.state.queueing){
                        clearInterval(_this.waitItvl);
                        _this.$emit('backEvent',{name:'bet'})
                    }
                    if(_this.curWId >= 5){
                        _this.curWId=0;
                    }else{
                        _this.curWId += 1;
                    }

                },1000)
            },
            getApprovedAmount(){
                let _this = this;
                window.usdtToken.allowance(window.walletAddress, window._FOOTBALL_CONTRACT).call().then((res)=>{
                let _approvedAmount=0;
                if(res.remaining===undefined){
                    _approvedAmount = res.toNumber();
                }else{
                    _approvedAmount = res.remaining.toNumber();
                }
                _this.approvedAmount = _approvedAmount/1000000;
                });
            },
            closeHandle(){
                if(!this.submited){
                    this.$emit('backEvent')
                }
            },
            //列表点击
            itemHandle(e){
                let index=e.currentTarget.dataset.index;
                this.curid=index;
                
            },
            //完成点击
            btnHandle(){
                
                if(this.ismask)return;
                this.ismask=true;
                let score=this.list[this.curid];
                KICK({score:score,username:window.walletAddress}).then(res=>{
                    console.log('kickRes:',res);
                    this.ismask=false;
                    this.$emit('backEvent',{name:'bet',score:score})
                })   
            },
            async bet(amount){
                if(this.ismask)return;
                this.ismask=true;
                if(this.curid==-1){
                    this.ismask=false;
                    return;
                }
                let _this = this;
                if(this.userUsdtBalance < amount){
                    console.log(this.userUsdtBalance, amount)
                    this.$message.info(this.$t('game.lackOfBalance'));
                    this.ismask=false;
                    return;
                }
                console.log(this.approvedAmount, amount);
                let _nonce = Math.floor(Math.random() * 10000);
                try{
                    if(this.approvedAmount < amount){
                        //授權
                        window.usdtToken.increaseApproval(window._FOOTBALL_CONTRACT, amount*100000000).send().then((res)=>{
                            setTimeout(()=>{
                                _this.getApprovedAmount();
                            },1500);
                            console.log(res)
                            if(window.footballContract){
                                window.footballContract.bet(amount*1000000, _nonce).send().then(()=>{
                                    _this.ismask = false;
                                    _this.$message.success(_this.$t('game.common.submitSuccess'));
                                    _this.submited = true;
                                    _this.startWait();
                                    _this.$store.commit("setQueueing", true);
                                    // setTimeout(()=>{
                                    //     _this.$emit('backEvent',{name:'bet',score:amount})

                                    // },3000)

                                });
                            }
                        });
                    }else{
                        if(window.footballContract){
                            await window.footballContract.bet(amount*1000000, _nonce).send().then(()=>{
                                _this.ismask = false;
                                _this.$message.success(_this.$t('game.common.submitSuccess'));
                                _this.submited = true;
                                _this.startWait();
                                _this.$store.commit("setQueueing", true);

                                // setTimeout(()=>{
                                //     _this.$emit('backEvent',{name:'bet',score:amount})

                                // },3000)
                                
                            });
                        }
                    }
                    
                }catch(e){
                    this.ismask = false;
                    console.log(e)
                }
            },
        }
    }
</script>


<style lang="scss">
    @import './bet.scss'
</style>