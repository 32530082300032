import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);


// 准备翻译的语言环境信息
const messages = {
    en: {
        game:require('./game/en.json')
    },
    cn: {
        game:require('./game/zh-CN.json')
    },
    es: {
        game:require('./game/es.json')
    },
    pk: {
        game:require('./game/pk.json')
    },
    pt: {
        game:require('./game/pt.json')
    },
    ru: {
        game:require('./game/ru.json')
    },
    tr: {
        game:require('./game/tr.json')
    },
    vn: {
        game:require('./game/vn.json')
    }
}

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
    locale: 'en', // 设置地区
    messages, // 设置地区信息
})



export default i18n;