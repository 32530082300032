

import { post,get } from "./http";


//#登录
export const LOGIN=(params)=>post('api/football/login',params,false);

//#玩法说明
export const  HOWTOPLAY=(params)=>get('api/football/howtoplay',params,false);


//#记录
export const  RECORDS=(params)=>post('api/football/records',params,false);

//#下注
export const KICK=(params)=>post('api/football/kick',params,false);


