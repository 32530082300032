<template>
    <div id="game">
        <div id="home">
            <div class="box" id="box">
                
                <canvas id="mycanvas"></canvas>
                <div class="topbar" id="topbar">
                    <span class="back" @click="goHome()">&lt;</span> <span class="title">Crazy Football</span>
                </div>
                <div class="rule" @click="ruleHandle">
                    <div class="icon"><img src="../../assets/img/icon0.png" /></div>
                    <div class="text">{{$t('game.howtoplay')}}</div>
                </div>
                <div class="rule notes" @click="notesHandle">
                    <div class="icon"><img src="../../assets/img/icon1.png" /></div>
                    <div class="text">{{$t('game.records')}}</div>
                </div>
                <div class="btn0 animated08 fadeInScale" v-if="startBtnShow==0" @click="betHandle">
                    <!-- <img src="../../assets/img/btn0.png" /> -->
                    <img src="../../assets/img/timerbg.png" />
                    <div class="num">{{$t('game.betnow')}}</div>
                </div>
                <!-- <div class="btn0 animated05 fadeInScaleMax" v-if="startBtnShow==1">
                    <img src="../../assets/img/timerbg.png" />
                    <div class="num">{{timerId}}</div>
                </div> -->
                <div v-if="startBtnShow==2" >
                    <div class="btn0 animated05 fadeInScaleMax"@click="startGame">
                        <img src="../../assets/img/timerbg.png" />
                        <div class="num">{{$t('game.shoot')}}</div>
                        
                        <!-- <img src="../../assets/img/start.png" /> -->
                    </div>
                    <div class="tips">
                        {{$t('game.willShoot',{sec:timerId})}}
                    </div>
                </div>
                
            </div>
            <!-- <div id="menu"><img src="../../assets/img/menu.png" /></div> -->
        </div>

        <!-- 进球 -->
        <win v-if="popShow=='win'" :score="reward/1000000" @backEvent="popClose" />
        <fail v-else-if="popShow=='fail'" @backEvent="popClose"></fail>
        <notes v-else-if="popShow=='notes'" @backEvent="popClose"></notes>
        <bet v-else-if="popShow=='bet'" @backEvent="popClose"></bet>
        <rule v-else-if="popShow=='rule'" @backEvent="popClose"></rule>

    </div>
</template>


<script>
import Loading from './Loading'
import Win from '../../components/game/win/win.vue'
import Fail from '../../components/game/fail/fail.vue'
import Notes from '../../components/game/notes/notes.vue'
import Bet from '../../components/game/bet/bet.vue'
import Rule from '../../components/game/rule/rule.vue'
import { LOGIN } from '../../utils/api'

import Echo from "laravel-echo"
window.Pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'e334b9d24be3e591d3b5',
    cluster: 'ap3',
    encrypted: true    //与broadcasting.php保持一致
});


export default {
    name: 'game',
    components: {
        Win, Fail, Notes, Bet, Rule
    },
    data: function () {
        return {
            lib: null,
            startBtnShow: 0,//0下注|1倒计时|2射门|3隐藏
            popShow: '',//win-进球、fail-未进球、notes-记录、bet-下注列表、rule-玩法说明
            isLottery: false,//是否中奖
            score: 0,//用户选择的分数,
            reward: 0,//结果
            isStart: false,//是否开始游戏
            timerTotal: 3,//倒计时总数
            timerId: 3,
            autoShootDelay: 3,
            delayTimer: null,//延时
            timer: null,
          
        }
    },
    mounted() {

        let that = this;
        let loading = new Loading(res => {
            this.lib = res;
            this.initCanvas();
            this.startBtnShow = 0;
        });

        // console.log($t("message.hello"));
        // this.setLogin();

        // Enable pusher logging - don't include this in production
        // Pusher.logToConsole = true;
        // 频道绑定事件 footwin-event.{用户username}
        let _obj = setInterval(()=>{
            if (window.walletAddress) {
                clearInterval(_obj);
                window.Echo.channel('footwin-channel').listen(".footwin-event."+window.walletAddress, (res) => {
                    console.log('wsdata:', res);
                    that.$store.commit('setQueueing', false);
                    that.isLottery = res.data.win;
                    that.reward = res.data.reward;
                    that.startTimer();
                    that.startBtnShow = 2;
                });
            }
            
        }, 50);
        

    },
    methods: {
        goHome(){
            window.location.href = "https://fomo2022.com/#/?lang="+this.$i18n.locale;
        },
        //开启倒记时
        startTimer() {
            this.timerId = this.timerTotal;
            this.timer = setInterval(() => {
                this.timerId--;
                // if (this.timerId > 0) {
                //     this.startBtnShow = 3;
                //     setTimeout(() => {
                //         this.startBtnShow = 1;
                //     }, 10)
                // }

                
                if (this.timerId == 0) {
                    clearInterval(this.timer);
                    // this.startDelayTimer();
                    this.startGame();
                    
                }
            }, 1000)
        },

        //自动踢球
        startDelayTimer() {
            // this.startBtnShow = 3;
            this.delayTimer = setTimeout(() => {
                console.log(this.startBtnShow );
                this.startGame();
            }, this.autoShootDelay*1000)
        },
        // 初始化canavs
        initCanvas() {

            this.main = null;

            let canvas = document.getElementById('mycanvas');
            let box = { width: document.getElementById('box').clientWidth, height: document.getElementById('box').clientHeight }
            let full = { width: document.documentElement.clientWidth, height: document.documentElement.clientWidth }
            let scale = Math.max(full.width / 750, full.height / 1623);

            this.main = new this.lib.main_mc();
            let stage = new this.lib.Stage(canvas);
            this.main.scaleX = this.main.scaleY = scale;


            //1623 y轴的值
            this.main.y = (box.height - 1623 * scale)
            // this.main.y=-100
            // console.log(box);
            // console.log(1623 * scale);
            // console.log(this.main.y);
            canvas.width = box.width;
            canvas.height = box.height;

            stage.addChild(this.main);
            createjs.Ticker.framerate = this.lib.properties.fps;
            createjs.Ticker.addEventListener("tick", stage);

        },
        
        //开始游戏
        startGame() {
            const that = this;

            clearInterval(this.delayTimer)
            clearInterval(this.timer)

            this.isStart = true;
            //正确失败随机测试用
            // this.isLottery = Math.random() > 0.5 ? true : false;
            // this.isLottery=false;

            this.startBtnShow = 3;
            this.main.gotoAndStop(1);
            this.main.foot_mc.gotoAndPlay(1);

            let mathId = this.getMathId();

            let curName = this.isLottery ? 'win' : 'fail';
            this.curItem = this.main[curName + mathId];

            this.curItem.gotoAndPlay(1);
            this.curItem.addEventListener('ballComplete', ballComplelte);

            //游戏结束
            function ballComplelte() {

                that.isStart = false;
                that.curItem.removeEventListener('ballComplete', ballComplelte);
                that.popShow = curName;
            }

        },
        //获取动画随机数
        getMathId() {
            let arr = [0, 1, 2, 3, 1, 0, 2, 3];
            let math = Math.floor(Math.random() * arr.length);
            return arr[math];
        },
        restGame() {
            this.curItem.gotoAndStop(0);
            this.main.gotoAndStop(0);
            this.main.foot_mc.gotoAndStop(0);
            this.startBtnShow = 0;
            this.score = 0;
        },

        //玩法说明
        ruleHandle() {
            this.popShow = 'rule';
        },

        //下注
        betHandle() {
            this.popShow = 'bet';
            // this.startGame();

        },
        //游戏记录按钮点击
        notesHandle() {
            this.popShow = 'notes';
            // this.$i18n.locale='en'
        },
        // 关闭弹窗
        popClose(parm) {
            let obj = parm || { name: '' }
            this.popShow = '';
            switch (obj.name) {
                case 'game':
                    this.restGame();
                    break;
                case 'bet':
                    // this.score = obj.score;
                    // this.isLottery = obj.isLottery;
                    // this.reward = obj.reward;
                    // this.startTimer();
                    // this.startBtnShow = 2;
                    // this.startDelayTimer();
                    break;
            }


        }
    }
}
</script>


<style lang="scss">
@import './animate.css';
@import './game.scss';
</style>