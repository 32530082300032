import Vue from 'vue'
import App from './App.vue'

import 'amfe-flexible/index'
import store from './store/index'

import i18n from './i18n/i18n.js'

import querystring from "query-string"
Vue.prototype.$querystring = querystring

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.config.productionTip = false
Vue.use(Antd);

new Vue({
  store,i18n,
  render: h => h(App),
}).$mount('#app')
