<template>
    <div id="rule">
        <div class="box animated fadeInToAlpha">
            <div class="winbg"></div>
            <div class="titlebg"></div>
            <div class="wintitle">{{$t('game.howtoplay')}}</div>
            <div class="close" @click="closeHandle"></div>
            <div class="textscroll">
                <!-- <div class="text" v-html="text">
                </div> -->
                <div class="text">
                    <p v-for="t,i in $t('game.howtoplaytxt')" :key="'htp'+i">
                        {{t}}
                    </p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {HOWTOPLAY} from '../../../utils/api';
export default {
    name: 'rule',
    data: function () {
        return {
            text:null,
        }
    },
    mounted(){
        // this.getRule();
    },
    methods: {

        //获取内容
        getRule(){
            HOWTOPLAY({'username':window.walletAddress}).then(res=>{
                this.text=res.data.data.content;
                console.log('res',res);
            })

        },
        //关闭点击
        closeHandle() {
            this.$emit('backEvent')
        }
    }
}
</script>


<style lang="scss">
@import './rule.scss'
</style>