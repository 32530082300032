<template>
    <div id="win">
        <div class="box  animated fadeInToAlpha">
            <div class="winbg"></div>
            <div class="titlebg"></div>
            <div class="winicon animated fadeInScale delay02"></div>
            <div class="wintitle"></div>
            <div class="close" @click="closeHandle"></div>
            <div class="wintext  animated fadeInToAlpha delay05">
                {{$t('game.win.text')}}
            </div>
            <div class="text  animated fadeInToAlpha delay05">
                {{$t('game.windec.text1')}} <span>{{score}}</span> {{$t('game.windec.text2')}}
            </div>
            <div class="backbtn  animated08 fadeInScale delay05" @click="closeHandle">
                <cbtn colorClass="light" :txt="$t('game.win.ok')" />
            </div>
            
            
        </div>
    </div>
</template>

<script>
    import Cbtn from '../cbtn/cbtn.vue'

    export default{
        name:'win',
        props:{
            score:0
        },
        components: {
            Cbtn
        },
        data:function(){
            return{

            }
        },
        methods:{
            closeHandle(){
                this.$emit('backEvent',{name:'game'})
            }
        }
    }
</script>


<style lang="scss">
    @import './win.scss'
</style>