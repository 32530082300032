

export default class Loading{
    constructor(back){
        this.back=back;
        console.log('loading');
        this.init();
    }
    init () {
        let that = this;
        var comp = AdobeAn.getComposition("6B076430D1A2423489D5A2EC3A00738C");
        var lib = comp.getLibrary();
        var loader = new createjs.LoadQueue(false);
        createjs.MotionGuidePlugin.install();
        loader.addEventListener("fileload", evt=> { that.handleFileLoad(evt, comp) });
        loader.addEventListener("complete", evt=> { that.handleComplete(evt, comp) });
        var lib = comp.getLibrary();
        loader.loadManifest(lib.properties.manifest);
    }

     handleFileLoad(evt, comp) {
        var images = comp.getImages();
        if (evt && (evt.item.type == "image")) { images[evt.item.id] = evt.result; }
    }

     handleComplete(evt, comp) {



        var lib = comp.getLibrary();
        var ss = comp.getSpriteSheet();
        var queue = evt.target;
        var ssMetadata = lib.ssMetadata;
        for (var i = 0; i < ssMetadata.length; i++) {
            ss[ssMetadata[i].name] = new createjs.SpriteSheet({ "images": [queue.getResult(ssMetadata[i].name)], "frames": ssMetadata[i].frames })
        }

        // console.log(this);
        this.back(lib);
        return;
    
        
    }

}
// const Loading = {


//     init: function () {
//         let that = this;
//         var comp = AdobeAn.getComposition("6B076430D1A2423489D5A2EC3A00738C");
//         var lib = comp.getLibrary();
//         var loader = new createjs.LoadQueue(false);
//         loader.addEventListener("fileload", function (evt) { handleFileLoad(evt, comp) });
//         loader.addEventListener("complete", function (evt) { handleComplete(evt, comp) });
//         var lib = comp.getLibrary();
//         loader.loadManifest(lib.properties.manifest);
//     },



// }

// function handleFileLoad(evt, comp) {
//     var images = comp.getImages();
//     if (evt && (evt.item.type == "image")) { images[evt.item.id] = evt.result; }
// }

// function handleComplete(evt, comp) {



//     var lib = comp.getLibrary();
//     var ss = comp.getSpriteSheet();
//     var queue = evt.target;
//     var ssMetadata = lib.ssMetadata;
//     for (var i = 0; i < ssMetadata.length; i++) {
//         ss[ssMetadata[i].name] = new createjs.SpriteSheet({ "images": [queue.getResult(ssMetadata[i].name)], "frames": ssMetadata[i].frames })
//     }

//     let canvas = document.getElementById('mycanvas'),
//         exportRoot = new lib.main_mc(),
//         stage = new lib.Stage(canvas);



    
    
//     let box={width:document.getElementById('box').clientWidth,height:document.getElementById('box').clientHeight}
//     let full={width:document.documentElement.clientWidth,height:document.documentElement.clientWidth}
//     let scale=Math.max(full.width/750,full.height/1334);
//     exportRoot.scaleX=exportRoot.scaleY=scale;
//     exportRoot.y=(box.height-1210*scale)

//     canvas.width = box.width;
//     canvas.height = box.height;

//     stage.addChild(exportRoot);
//     createjs.Ticker.framerate = lib.properties.fps;
//     createjs.Ticker.addEventListener("tick", stage);
// }

// export { Loading }