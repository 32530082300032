<template>
  <div id="app">
    
    <Game />
  </div>
</template>

<script>
import Game from './pages/game/game.vue'

export default {
  name: 'App',
  components: {
    Game,
  },
  mounted() {
    let {lang} = this.$querystring.parse(location.search.replace('?',''));
    if(lang) this.$i18n.locale = lang;
  }
}
</script>

<style lang="scss">
#app {
  width: 100vw;
}

@mixin bg-img($url) {
  background-image: url($url);
  background-repeat: no-repeat;
  // background-size: cover;
  // background-size:100% 100%;
  background-position: center;
}
</style>
