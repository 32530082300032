<template>
    <div class="cbtn">
        <div :class="colorClass" @click="clickHandle">
            {{txt}}
        </div>
    </div>
</template>

<script>
    export default{
        name:'cbtn',
        props:{
            txt: {
                type: String,
                default: 'DONE'
            },
            light: {
                type: Boolean,
                default: true
            },
            bg: {
                type: String
            },
            colorClass: {
                type: String,
                default: 'gray'
            }
        },
        data:function(){
            return{

            }
        },
        methods:{
            clickHandle(){
                this.$emit('backEvent')
            }
        }
    }
</script>


<style lang="scss">
    @import './cbtn.scss'
</style>